<template>
  <div class="d-flex align-items-center">
    <div class="image-wrapper">
      <b-img v-if="preview[refName]" :src="preview[refName]" :key="key" class="image-preview" />
      <span v-else class="image-preview">image</span>
    </div>

    <div class="mr-0 d-flex align-items-center">
      <b-form-file
        v-model="image[refName]"
        :accept="availableImagesTypes"
        :ref="refName"
        placeholder="Choose a image or drop it here..."
        drop-placeholder="Drop image here..."
        @change="handleFileUpload"
      />

      <b-button
        v-show="isDeliteButton"
        variant="gradient-danger"
        class="btn-icon"
        :disabled="!preview[refName]"
        @click.prevent="deleteImage"
      >
        <feather-icon icon="Trash2Icon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BFormFile, BImg } from 'bootstrap-vue'

const imageMimeTypes = {
  JPEG: 'image/jpeg',
  JPG: 'image/jpeg',
  PNG: 'image/png',
}
export const availableImagesTypes = Object.values(imageMimeTypes)

export default {
  name: 'ImageUploader',
  components: { BFormFile, BButton, BImg },
  props: {
    refName: {
      type: String,
      default: 'avatar',
    },

    previewImage: {
      type: String,
      default: '',
    },

    hidePreview: {
      type: Boolean,
      default: false,
    },

    isDeliteButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      key: Math.random(),
      image: {},
      preview: {
        [this.refName]: this.previewImage,
      },
      availableImagesTypes: availableImagesTypes.toString(),
    }
  },

  watch: {
    previewImage: {
      handler(value) {
        this.preview[this.refName] = value
      },
    },
  },

  methods: {
    handleFileUpload(event) {
      const name = event.target.name
      const file = event.target.files[0]
      this.image[this.refName] = file

      this.$emit('change', file)

      this.createImagePreview(file, this.refName)
    },

    createImagePreview(file, name) {
      const reader = new FileReader()
      reader.onload = e => {
        this.preview[name] = e.target.result
        this.key = Math.random()
      }
      reader.readAsDataURL(file)
    },

    deleteImage() {
      this.$refs[this.refName].reset()
      this.image[this.refName] = ''
      this.preview[this.refName] = ''
      this.key = Math.random()
      this.$emit('delete')
    },

    addImage(ref) {
      this.$refs[ref].click()
    },
  },
}
</script>

<style>
.image-wrapper {
  padding: 0 1.5rem;
}

.image-preview {
  width: 55px;
  min-width: 55px;
  min-height: 55px;
  man-width: 55px;
  man-height: 55px;
  border: 0px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}
</style>
