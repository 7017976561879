<template>
  <b-card>
    <div>
      <validation-observer ref="provider">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="Name" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-row>
                <ImageUploader :preview-image="form.image" @change="handleImageUploade" @delete="handleImageDelete" />
              </b-row>
            </b-col>

            <b-col md="6">
              <b-form-group label="Position" label-for="position">
                <validation-provider #default="{ errors }" name="position" rules="required">
                  <b-form-input
                    v-model="form.position"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="position"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Description" label-for="description">
                <validation-provider #default="{ errors }" name="description" rules="required">
                  <b-form-input
                    v-model="form.description"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="description"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Publish" label-for="isPublish">
                <validation-provider #default="{ errors }" name="isPublish">
                  <b-form-checkbox v-model="form.isPublish" type="number" :state="errors.length > 0 ? false : null" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Order" label-for="order">
                <validation-provider #default="{ errors }" name="order" rules="required">
                  <b-form-input
                    v-model.number="form.order"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="order"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="X link" label-for="xLink">
                <validation-provider #default="{ errors }" name="xLink">
                  <b-form-input
                    v-model="form.xLink"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="link"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Linkedin Link" label-for="linkedinLink">
                <validation-provider #default="{ errors }" name="linkedinLink">
                  <b-form-input
                    v-model="form.linkedinLink"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="link"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
            {{ memberId ? 'Edit' : 'Create' }}
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BFormCheckbox } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required } from '@validations'
import ImageUploader from '@/components/ImageUploader.vue'
import { objectToFormData } from '@/tools/helpers'
export default {
  name: 'TeamsCreate',
  components: {
    ImageUploader,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      memberId: null,
      imageFile: null,
      form: {
        name: '',
        file: '',
        position: '',
        description: '',
        isPublish: false,
        order: null,
        linkedinLink: '',
        xLink: '',
      },
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchMemberById: 'team/fetchMemberById',
      createMember: 'team/createMember',
      updateMember: 'team/updateMember',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)

      if (!this.$route.params.id) return

      this.memberId = this.$route.params.id
      this.getMemberById(this.memberId)
    },

    create() {
      const formData = objectToFormData({
        ...this.form,
        file: this.imageFile,
      })

      this.waitRequest(() => {
        return this.createMember(formData)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Member is added',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    update() {
      const formData = objectToFormData({
        ...this.form,
        file: this.imageFile,
      })

      this.waitRequest(() => {
        return this.updateMember({ id: this.memberId, form: formData })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Member is updated',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    getMemberById(memberId) {
      this.waitRequest(() => {
        return this.fetchMemberById(memberId)
          .then(response => {
            this.form = response.data
            this.form.linkedinLink = this.form.linkedinLink ?? ''
            this.form.xLink = this.form.xLink ?? ''
            this.form.image = this.form.image ?? ''
          })
          .catch(this.checkErrors)
      })
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      if (this.memberId) return this.update()

      this.create()
    },

    handleImageUploade(image) {
      console.log('image')
      this.imageFile = image
    },

    handleImageDelete() {
      this.form.image = ''
      this.imageFile = null
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
